import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/style/dsfr.min.css");
;
import(/* webpackMode: "eager" */ "/app/style/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../style/fonts/Marianne-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../style/fonts/Marianne-Regular_Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../style/fonts/Marianne-Bold.woff2\",\"weight\":\"900\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-marianne\"}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/app/style/icons/logo/github-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/style/icons/logo/linkedin-box-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/style/icons/logo/twitter-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/style/icons/system/external-link-line.svg");
;
import(/* webpackMode: "eager" */ "/app/style/icons/system/search-line.svg");
